<template>
  <div>
    <v-card flat :loading="loading">
      <v-row class="pa-3" v-if="!$vuetify.breakpoint.mobile">
        <v-col cols="12" sm="6" class="mb-0 pb-0">
          <v-row>
            <v-col
              cols="12"
              class="mt-0 py-0 d-flex align-center"
              style="height: 40px"
            >
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-chip
                    small
                    outlined
                    class="primaryText--text mx-1 smallText"
                    color="blue-grey"
                    v-on="on"
                  >
                    <v-icon x-small left>event</v-icon>
                    {{
                      quickSelectDates.find(
                        (x) => x.value == params.selectedDate
                      )
                        ? quickSelectDates.find(
                            (x) => x.value == params.selectedDate
                          ).text
                        : "Select Date"
                    }}<v-icon small right>expand_more</v-icon>
                  </v-chip>
                </template>
                <v-list style="overflow: hidden" dense>
                  <v-list-item
                    v-for="(date, index) in quickSelectDates"
                    :key="index"
                    @click="selectDate(date.value)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ date.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu
                ref="menu"
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    color="blue"
                    class="mx-1"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    small
                    ><v-icon small left>date_range</v-icon>{{ dateText }}
                    <v-icon small right>expand_more</v-icon></v-chip
                  >
                </template>
                <v-date-picker
                  v-model="params.dates"
                  range
                  color="primary"
                  show-week
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary"> Cancel </v-btn>
                  <v-btn text color="primary" @click="setDateFilter">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-chip
                :key="dateKey"
                :color="params.dateType == 'ETD' ? 'teal' : 'blue-grey'"
                class="mx-1"
                small
                @click="changeDateType()"
              >
                <v-icon small left>event</v-icon> Date Type:
                {{ params.dateType }}
              </v-chip>
            </v-col>
            <v-col cols="12">
              <v-chip
                id="team-select-one"
                small
                class="mx-1"
                outlined
                :color="
                  selectedTeams.includes(team.id) ? team.colour : 'darkgrey'
                "
                v-for="team in allTeams"
                :key="team.id"
                @click="selectTeam(team.id)"
              >
                <v-icon small left v-if="selectedTeams.includes(team.id)"
                  >check</v-icon
                >
                {{ team.name }}</v-chip
              >
              <v-chip
                id="team-select-all"
                outlined
                color="success"
                small
                v-if="selectedTeams.length < allTeams.length"
                @click="selectAllTeams()"
                >Select All</v-chip
              >
            </v-col>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="6" class="pa-0">
          <v-col cols="12" class="text-right pa-0 mb-0">
            <v-chip
              style="height: 40px"
              outlined
              small
              class="pr-1 text-center"
            >
              <v-menu offset-y rounded>
                <template v-slot:activator="{ on }">
                  <v-chip
                    style="text-transform: none"
                    :color="importExport == 0 ? 'deep-orange' :  'blue darken-2'"
                    rounded
                    v-on="on"
                    small
                  >
                    <div v-if="importExport == 0">
                      <v-icon small left>filter_alt</v-icon>
                      All Bookings
                    </div>
                    <div v-else-if="importExport == 1">
                      <v-icon small left>filter_alt</v-icon>
                      Exports
                    </div>
                    <div v-else-if="importExport == 2">
                      <v-icon small left>filter_alt</v-icon>
                      Imports
                    </div>
                    <div v-else-if="importExport == 3">
                      <v-icon small left>filter_alt</v-icon>
                      Local
                    </div>
                  </v-chip>
                </template>
                <v-list dense>
                  <v-list-item @click="changeImportExportFilter('ALL')">
                    <v-list-item-content>
                      <v-list-item-title> All Bookings </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="changeImportExportFilter('EXPORT')">
                    <v-list-item-content>
                      <v-list-item-title> Exports </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="changeImportExportFilter('IMPORT')">
                    <v-list-item-content>
                      <v-list-item-title> Imports </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="changeImportExportFilter('LOCAL')">
                    <v-list-item-content>
                      <v-list-item-title> Local </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- <v-btn
                small
                outlined
                rounded
                class="mx-1"
                @click="changeImportExportFilter"
              >
              </v-btn> -->
              <v-menu offset-y :disabled="selectedBookings.length == 0">
                <template v-slot:activator="{ on }">
                  <v-chip
                    small
                    class="primaryText--text mx-1"
                    :color="selectedBookings.length > 0 ? 'blue' : 'grey'"
                    v-on="on"
                    style="border: none"
                  >
                    Actions<v-icon small right>expand_more</v-icon>
                  </v-chip>
                </template>
                <v-list style="overflow: hidden" dense>
                  <!-- <v-list-item style="height:35px" @click="bulkUpdateStatusModal = true">
                            <v-list-item-action>
                                <v-icon color="grey">fiber_manual_record</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Update Status
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item> -->
                  <v-list-item
                    style="height: 35px"
                    @click="q67CommentModal = true"
                  >
                    <v-list-item-action>
                      <v-icon color="grey">article</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Bulk Create Q67 </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    style="height: 35px"
                    @click="downloadBookingData"
                  >
                    <v-list-item-action>
                      <v-icon color="grey">save_alt</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Download Booking Data
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-chip small class="mr-1"
                >TOTAL:
                <span style="margin-left: 5px">
                  {{ displayData.length }}</span
                >
              </v-chip>
              <v-chip color="blue-grey" small class="mr-1">
                <span>
                  {{ countContainers }} Containers</span
                >
              </v-chip>
              <v-chip @click="showCancelled = !showCancelled" :color="showCancelled ? 'redPop' : ''" small class="mr-1">
                <span class="textCancelled"> Show Cancelled </span>
              </v-chip>
              <v-btn icon color="primary" @click="addBooking()"
                ><v-icon>add_circle_outline</v-icon></v-btn
              >
              <v-tooltip top open-delay="500">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-menu
                      offset-y
                      nudge-right
                      :close-on-content-click="false"
                      style="height: 20vh; margin-right: 8vw"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon
                          ><v-icon>view_week</v-icon></v-btn
                        >
                      </template>
                      <v-card
                        style="
                          background: var(--v-modal-base) !important;
                          border-radius: 20px;
                        "
                      >
                        <v-card-text>
                          <v-list
                            style="
                              overflow: hidden;
                              column-count: 3;
                              width: 400px;
                              height: fit-content;
                            "
                          >
                            <v-checkbox
                              v-for="(item, index) in headers.filter(
                                (x) => x.value != 'action'
                              )"
                              :key="index"
                              v-model="item.show"
                              @change="toggleColumn(item)"
                              dense
                              :label="item.text"
                              hide-details
                            ></v-checkbox>
                          </v-list>
                          <v-row class="pt-5 pb-4" style="overflow-y: hidden">
                            <v-col
                              cols="12"
                              md="12"
                              sm="12"
                              lg="12"
                              class="d-flex justify-center"
                            >
                              <v-btn
                                @click="saveCustomHeaders"
                                :loading="loadingSaveCustom"
                                outlined
                                color="primary"
                                >SAVE</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </template>
                Customize Layout
              </v-tooltip>
              <v-text-field
                placeholder="Search"
                class="mb-1"
                hide-details
                rounded
                clearable
                dense
                style="width: 300px"
                v-model="search"
              ></v-text-field>
            </v-chip>
          </v-col>
          <v-col cols="12" class="d-flex justify-end" :key="updateFilterValue">
            <div :key="updateFilterValue">
              <v-chip
                color="red"
                class="mx-1"
                small
                @click="resetFilters()"
                v-if="filteredValues.length > 0"
              >
                <v-icon small left>filter_alt_off</v-icon> Clear Filters
              </v-chip>
              <v-chip
                class="mx-1"
                close
                small
                @click:close="addToFilter(filterValue.value, filterValue.field)"
                v-for="(filterValue, index) in filteredValues"
                :key="index"
              >
                {{ filterValue.value }}
              </v-chip>
              <v-chip
                v-if="settingsChange"
                color="edit"
                small
                rounded
                outlined
                @click="saveCustomHeaders()"
              >
                <v-progress-circular
                  v-if="loadingSaveCustom"
                  size="18"
                  width="2"
                  indeterminate
                  color="edit"
                ></v-progress-circular>
                <v-icon v-if="!loadingSaveCustom" small left>save</v-icon>
                <span v-if="!loadingSaveCustom">Save Layout</span>
              </v-chip>
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-card-text
        v-if="!$vuetify.breakpoint.mobile"
        style="max-width: 100%; overflow-x: auto"
        class="my-0 px-0"
      >
        <div :style="{ width: tableWidth }" v-if="displayData.length > 0">
          <table :width="'100%'">
            <tr class="mx-0 px-0">
              <draggable
                v-model="selectedHeaders"
                tag="tr"
                :style="{ cursor: columnDrag ? 'grabbing' : 'grab' }"
                :animation="200"
                @change="updateUserLayout()"
                ghost-class="ghost-card"
                @start="columnDrag = true"
                @end="columnDrag = false"
              >
                <td
                  class="text-center mx-0 px-0"
                  style="
                    cursor: grab;
                    border-right: 1px solid grey;
                    min-height: 20px;
                    min-width: 20px;
                  "
                  v-for="(header, index) in selectedHeaders"
                  :key="index"
                  :width="header.width ?? '150px'"
                >
                  <div>
                    <v-row align="center" justify="center" class="py-2">
                      <v-checkbox
                        v-if="header.value == 'actions'" :indeterminate=" selectedBookings.length > 0 && selectedBookings.length < filteredResults.length"
                        hide-details v-model="selectAllBookings" dense class="mx-0 px-0 mb-2"
                      ></v-checkbox>
                      <b style="font-size: 12px">{{ header.text }}</b>
                      <v-btn
                        v-if="!header.disableSort"
                        class="ml-1"
                        icon
                        x-small
                        @click="changeSortBy(header.value)"
                      >
                        <v-icon v-if="sortBy != header.value" small color="grey"
                          >arrow_upward</v-icon
                        >
                        <v-icon v-else-if="sortDesc" small
                          >arrow_downward</v-icon
                        >
                        <v-icon v-else small>arrow_upward</v-icon>
                      </v-btn>
                      <v-badge
                        v-if="!header.disableFilter"
                        :content="
                          filters[header.value]
                            ? filters[header.value].length
                            : false
                        "
                        :value="
                          filters[header.value] &&
                          filters[header.value].length > 0
                        "
                        color="primary"
                        offset-y="10"
                      >
                        <v-menu offset-y left :close-on-content-click="false">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              x-small
                              color="grey"
                              icon
                              ><v-icon small>filter_alt</v-icon></v-btn
                            >
                          </template>
                          <v-card
                            style="
                              background: var(--v-modal-base) !important;
                              border-radius: 20px;
                            "
                          >
                            <v-card-text>
                              <v-list
                                dense
                                style="
                                  background: var(--v-modal-base) !important;
                                  border-radius: 20px;
                                "
                              >
                                <v-text-field
                                  hide-details
                                  rounded
                                  dense
                                  outlined
                                  clearable
                                  prepend-inner-icon="search"
                                  placeholder="Search"
                                  autofocus
                                  v-model="searchHeaders[header.value]"
                                  @input="updateSearchKey()"
                                ></v-text-field>
                                <v-list
                                  dense
                                  style="
                                    max-height: 50vh;
                                    overflow-y: auto;
                                    background: var(--v-modal-base) !important;
                                  "
                                  :key="searchKey"
                                >
                                  <v-list-item
                                    v-for="(item, index) in headerOptions(
                                      header.value
                                    )"
                                    :key="index"
                                  >
                                    <v-list-item-action class="pr-0">
                                      <v-checkbox
                                        v-model="filters[header.value]"
                                        :value="item.value"
                                        hide-details
                                        dense
                                        @change="calculateFilteredResults()"
                                      ></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ item.name }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item
                                    v-if="
                                      headerOptions(header.value).length == 0
                                    "
                                  >
                                    <v-list-item-content class="text-center">
                                      <span style="font-size: 12px"
                                        >No results found</span
                                      >
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-list>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </v-badge>
                    </v-row>
                  </div>
                </td>
              </draggable>
            </tr>
          </table>
          <v-divider></v-divider>
          <div
            class="mt-1 pt-1 px-1"
            :style="{ width: '100%' }"
            v-if="displayData.length > 0"
          >
            <v-virtual-scroll
              v-if="displayData.length > 0"
              :bench="10"
              :items="displayData"
              :height="calculatedHeight"
              :width="'100%'"
              item-height="35"
              class="px-1"
            >
              <template v-slot:default="{ item }">
                <div
                  class="text-center my-0 py-0"
                  :style="{
                    display: 'flex',
                    'flex-direction': 'row',
                    width: '100%',
                    'background-color':
                      selectedBookingItem && selectedBookingItem.id == item.id
                        ? 'rgba(12, 97, 24, 0.6)'
                        : 'transparent',
                  }"
                  @contextmenu="viewItem($event, item)"
                >
                  <td
                    v-for="(header, index) in selectedHeaders"
                    :key="index"
                    :style="{ width: header.width ?? '150px' }"
                    class="text-center mt-0 mb-0 pb-0"
                  >
                    <div v-if="header.value == 'actions'">
                      <v-row align="center" justify="center" class="py-1">
                        <v-checkbox
                          hide-details
                          dense
                          :value="
                            selectedBookings.map((x) => x.id).includes(item.id)
                          "
                          @change="selectBooking(item)"
                          class="mb-2 mx-0 px-0"
                        ></v-checkbox>
                        <v-btn icon @click="viewBooking(item)" small>
                          <v-icon small>launch</v-icon>
                        </v-btn>
                      </v-row>
                    </div>
                    <div v-if="header.value == 'state'" class="text-center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :color="statusColor(item.state)"
                            small
                            icon
                            v-on="on"
                            @click="addToFilter(item.state, 'state')"
                          >
                            <v-icon>{{ statusIcon(item.state) }}</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">{{
                          $Format.capitalizeFirstLetter(item.state)
                        }}</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-else-if="header.value == 'userFullName'"
                      class="text-center"
                    >
                      <v-tooltip top v-if="item.user">
                        <template v-slot:activator="{ on }">
                          <v-avatar
                            style="cursor: pointer"
                            size="24"
                            class="ml-1"
                            v-on="on"
                            :color="item.user.avatar ? 'white' : 'secondary'"
                            @click="
                              addToFilter(item.userFullName, 'userFullName')
                            "
                          >
                            <v-img
                              v-if="item.user.avatar"
                              contain
                              :src="item.user.avatar"
                            ></v-img>
                            <h4 v-else>
                              {{ item.user.firstname.charAt(0) }}
                            </h4>
                          </v-avatar>
                        </template>
                        <span style="font-size: 12px"
                          >{{ item.user.firstname }}
                          {{ item.user.surname }}</span
                        >
                      </v-tooltip>
                    </div>

                    <div v-else-if="header.value == 'type'" class="text-center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :color="bookingTypeColor(item.type)"
                            small
                            icon
                            v-on="on"
                            @click="addToFilter(item.type, 'type')"
                          >
                            <v-icon>{{ bookingTypeIcon(item.type) }}</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">{{
                          $Format.capitalizeFirstLetter(item.type)
                        }}</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-else-if="header.value == 'vesselVoyage'"
                      class="text-left"
                    >
                      <v-tooltip top v-if="item.vesselVoyage">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-on="on"
                            small
                            class="primaryText--text"
                            outlined
                            @click="
                              addToFilter(item.vesselVoyage, 'vesselVoyage')
                            "
                            style="border: none; font-size: 11px"
                          >
                            <v-icon
                              small
                              left
                              v-if="item.isTransshipment"
                              color="orange"
                              >airline_stops</v-icon
                            >
                            <v-icon v-else small left color="grey"
                              >navigation</v-icon
                            >
                            <span>{{ item.vesselVoyage }}</span>
                          </v-chip>
                        </template>
                        <span
                          >{{ item.vesselVoyage }}:
                          <span v-if="item.isTransshipment">Transshipment</span>
                          <span v-else>Direct</span>
                        </span>
                      </v-tooltip>
                    </div>
                    <div
                      v-else-if="header.value == 'portOfLoadValue'"
                      class="text-center"
                    >
                      <v-tooltip top v-if="item.portOfLoadValue">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            outlined
                            style="border: none; font-size: 11px"
                            v-on="on"
                            small
                            @click="
                              addToFilter(
                                item.portOfLoadValue,
                                'portOfLoadValue'
                              )
                            "
                          >
                            <v-avatar size="20" left>
                              <v-img
                                contain
                                :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue
                                  .substring(0, 2)
                                  .toLowerCase()}.svg`"
                              ></v-img>
                            </v-avatar>
                            {{ item.portOfLoadValue }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
                          item.portOfLoadCity
                        }}</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-else-if="header.value == 'finalDestinationValue'"
                      class="text-center"
                    >
                      <v-tooltip top v-if="item.finalDestinationValue">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            outlined
                            style="border: none; font-size: 11px"
                            v-on="on"
                            small
                            @click="
                              addToFilter(
                                item.finalDestinationValue,
                                'finalDestinationValue'
                              )
                            "
                          >
                            <v-avatar size="20" left>
                              <v-img
                                contain
                                :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
                                  .substring(0, 2)
                                  .toLowerCase()}.svg`"
                              ></v-img>
                            </v-avatar>
                            {{ item.finalDestinationValue }}
                          </v-chip>
                        </template>
                        <span style="font-size: 12px">{{
                          item.finalDestinationCity
                        }}</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-else-if="header.value == 'isConfirmedBooking'"
                      class="text-center"
                    >
                      <v-btn
                        small
                        icon
                        :color="item.isConfirmedBooking ? 'success' : 'red'"
                        @click="
                          addToFilter(
                            item.isConfirmedBooking,
                            'isConfirmedBooking'
                          )
                        "
                      >
                        <v-icon v-if="item.isConfirmedBooking" small
                          >check_circle_outline</v-icon
                        >
                        <v-icon v-else color="red" small>cancel</v-icon>
                      </v-btn>
                    </div>

                    <div
                      v-else-if="header.value == 'preadvise'"
                      class="text-center"
                    >
                      <v-btn
                        small
                        icon
                        :color="item.preadvise ? 'success' : 'red'"
                        @click="addToFilter(item.preadvise, 'preadvise')"
                      >
                        <v-icon v-if="item.preadvise" small
                          >check_circle_outline</v-icon
                        >
                        <v-icon v-else color="red" small>cancel</v-icon>
                      </v-btn>
                    </div>

                    <div
                      v-else-if="header.value == 'consigneeName'"
                      class="text-left"
                    >
                      <v-tooltip top v-if="item.consignee">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-if="item.consignee"
                            small
                            class="primaryText--text"
                            outlined
                            @click="
                              addToFilter(item.consigneeName, 'consigneeName')
                            "
                            v-on="on"
                            style="border: none"
                          >
                            <v-avatar
                              size="24"
                              :color="
                                item.consignee && item.consignee.logo
                                  ? 'white'
                                  : 'secondary'
                              "
                              left
                            >
                              <v-img
                                style=""
                                v-if="item.consignee.logo"
                                :src="item.consignee.logo"
                                contain
                              ></v-img>
                              <h3 v-else style="color: white">
                                {{ item.consigneeName.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ item.consigneeName }}
                          </v-chip>
                        </template>
                        <span>{{ item.consignee.name }}</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-else-if="header.value == 'transporterName'"
                      :class="item.transporter ? 'text-left' : 'text-center'"
                    >
                      <v-tooltip top v-if="item.transporter">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-if="item.transporter"
                            small
                            class="primaryText--text"
                            v-on="on"
                            outlined
                            @click="
                              addToFilter(
                                item.transporterName,
                                'transporterName'
                              )
                            "
                            style="border: none"
                          >
                            <v-avatar
                              size="20"
                              :color="
                                item.transporter && item.transporter.logo
                                  ? 'white'
                                  : 'secondary'
                              "
                              left
                            >
                              <v-img
                                v-if="item.transporter.logo"
                                :src="item.transporter.logo"
                                contain
                              ></v-img>
                              <h3 v-else style="color: white">
                                {{ item.transporterName.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ item.transporterName }}
                          </v-chip>
                        </template>
                        <span>{{ item.transporter.name }}</span>
                      </v-tooltip>
                      <v-btn
                        color="grey"
                        small
                        icon
                        v-else
                        @click="assignContainers([item])"
                        ><v-icon small>add_circle_outline</v-icon></v-btn
                      >
                    </div>

                    <div
                      v-else-if="header.value == 'customerName'"
                      class="text-left"
                    >
                      <v-tooltip top v-if="item.customer">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-if="item.customer"
                            small
                            class="primaryText--text"
                            v-on="on"
                            outlined
                            @click="
                              addToFilter(item.customerName, 'customerName')
                            "
                            style="border: none"
                          >
                            <v-icon
                              class="mr-2"
                              small
                              left
                              v-if="item.clientStatus"
                              :color="getClientStatusColor(item.clientStatus)"
                              >fiber_manual_record</v-icon
                            >
                            <v-avatar
                              size="20"
                              :color="
                                item.customer && item.customer.logo
                                  ? 'white'
                                  : 'secondary'
                              "
                              left
                            >
                              <v-img
                                v-if="item.customer.logo"
                                :src="item.customer.logo"
                                contain
                              ></v-img>
                              <h3 v-else style="color: white">
                                {{ item.customerName.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ item.customerName }}
                          </v-chip>
                        </template>
                        <span
                          >{{ item.customer.name }}
                          <span v-if="item.clientStatus">
                            - Status: {{ item.clientStatus }}</span
                          ></span
                        >
                      </v-tooltip>
                    </div>
                    <div
                      v-else-if="header.value == 'shipperName'"
                      class="text-left"
                    >
                      <v-tooltip top v-if="item.shipper">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-if="item.shipper"
                            small
                            class="primaryText--text"
                            v-on="on"
                            outlined
                            @click="
                              addToFilter(item.shipperName, 'shipperName')
                            "
                            style="border: none"
                          >
                            <v-avatar
                              size="20"
                              :color="
                                item.shipper && item.shipper.logo
                                  ? 'white'
                                  : 'secondary'
                              "
                              left
                            >
                              <v-img
                                v-if="item.shipper.logo"
                                :src="item.shipper.logo"
                                contain
                              ></v-img>
                              <h3 v-else style="color: white">
                                {{ item.shipperName.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ item.shipperName }}
                          </v-chip>
                        </template>
                        <span>{{ item.shipper.name }}</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-else-if="header.value == 'onBehalfShipperName'"
                      class="text-left"
                    >
                      <v-tooltip top v-if="item.onBehalfShipper">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-if="item.onBehalfShipper"
                            small
                            class="primaryText--text"
                            v-on="on"
                            outlined
                            @click="
                              addToFilter(
                                item.onBehalfShipperName,
                                'onBehalfShipperName'
                              )
                            "
                            style="border: none"
                          >
                            <v-avatar
                              size="20"
                              :color="
                                item.onBehalfShipper &&
                                item.onBehalfShipper.logo
                                  ? 'white'
                                  : 'secondary'
                              "
                              left
                            >
                              <v-img
                                v-if="item.onBehalfShipper.logo"
                                :src="item.onBehalfShipper.logo"
                                contain
                              ></v-img>
                              <h3 v-else style="color: white">
                                {{ item.onBehalfShipperName.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ item.onBehalfShipperName }}
                          </v-chip>
                        </template>
                        <span>{{ item.onBehalfShipper.name }}</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-else-if="header.value == 'shippingLineName'"
                      class="text-left"
                    >
                      <v-chip
                        v-if="item.shippingLine"
                        small
                        class="primaryText--text"
                        outlined
                        @click="
                          addToFilter(item.shippingLineName, 'shippingLineName')
                        "
                        style="border: none"
                      >
                        <v-avatar
                          size="24"
                          :color="
                            item.shippingLine && item.shippingLine.logo
                              ? 'white'
                              : 'secondary'
                          "
                          left
                        >
                          <v-img
                            v-if="item.shippingLine.logo"
                            :src="item.shippingLine.logo"
                            contain
                          ></v-img>
                          <h3 v-else style="color: white">
                            {{ item.shippingLineName.charAt(0) }}
                          </h3>
                        </v-avatar>
                        {{ item.shippingLineName }}
                      </v-chip>
                    </div>
                    <div v-else-if="header.value == 'transportStatus'">
                      <v-menu offset-y v-if="item[header.value]">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            small
                            class="primaryText--text"
                            :color="getStatusColor(item.transportStatus)"
                            v-on="on"
                            style="border: none"
                          >
                            {{ item[header.value] }}
                            <v-icon small right>expand_more</v-icon>
                          </v-chip>
                        </template>
                        <v-list style="overflow: hidden" dense>
                          <v-list-item
                            v-for="(status, index) in transportStatuses"
                            :key="index"
                            style="height: 35px"
                            @click="setStatus(item, status)"
                          >
                            <v-list-item-action>
                              <v-icon :color="status.color"
                                >fiber_manual_record</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ status.text }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    <div v-else-if="header.value == 'etd'">
                      <v-chip
                        v-if="item[header.value]"
                        small
                        class="primaryText--text"
                        outlined
                        @click="addToFilter(item[header.value], header.value)"
                        style="border: none"
                      >
                        {{ formatDate(item[header.value]) }}
                      </v-chip>
                    </div>
                    <div v-else-if="header.value == 'vesselLegs'">
                      <v-btn
                        v-if="item.sailingSchedule"
                        icon
                        small
                        outlined
                        @click="viewBookingLegs(item)"
                        :color="
                          item.sailingSchedule.legs == 1 ? 'success' : 'blue'
                        "
                        >{{ item.sailingSchedule.legs }}</v-btn
                      >
                    </div>
                    <div v-else-if="header.value == 'numContainers'">
                      <v-chip
                        v-if="item[header.value]"
                        small
                        @click="addToFilter(item[header.value], header.value)"
                      >
                        {{ item[header.value] }}
                      </v-chip>
                    </div>
                    <!-- <div v-else-if="header.value == 'bookingFileStatus'">
                          <template >
                            <v-chip
                              small
                              icon
                              outlined
                              @click="addToFilter(entry, header.value)"
                              :color="checkFileStatus(entry) === 'Done' ? 'success' : 'orange'"
                    
                            >
                              {{ checkFileStatus(entry) }}
                            </v-chip>
                          </template>
                      
              
                      </div> -->
                    <div v-else-if="header.value == 'bookingFileStatus'">
                      <v-tooltip top v-if="item[header.value].length > 0">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-if="item[header.value]"
                            small
                            icon
                            outlined
                            @click="
                              addToFilter(item[header.value], header.value)
                            "
                            :color="
                              item[header.value] === 'In Progress' ? 'blue' : item[header.value] === 'Pending' ? 'orange' :item[header.value] === 'Completed' ? 'success' :'red',                     
                            "
                            v-on="on"
                          >
                            {{ item[header.value] }}
                          </v-chip>
                        </template>
                        
                        <span
                          v-for="(item, index) in popupFileStauts(
                            item.shipmentFileTotals
                          )"
                          :key="index"
                          :style="{ color: item.color }"
                        >
                         
                         
                         
                         <div style="display: flex;flex-direction: row;width: 250px;justify-content: space-between;">
                          <p style="width: 60%;">  {{ item.total}}x {{ item.fileNumber  ? item.fileNumber : item.status }}</p>
                          <p style="width: 40%;" v-if='item.fileNumber'> {{ item.documentationStatus === 'CLOSED' ? item.documentationStatus : item.status }}</p>
                          </div>
                       
                        </span>
                      </v-tooltip>
                      <!-- <v-chip
                          v-if="item[header.value]"
                          small
                          icon
                          outlined
                          @click="addToFilter(item[header.value], header.value)"
                          :color="checkFileStatus(item[header.value]) === 'Done' ? 'success' : 'orange'"
                        >
                          {{ checkFileStatus(item[header.value]) }}
                        </v-chip> -->
                    </div>

                    <div v-else-if="header.value == 'eta'">
                      <v-chip
                        v-if="item[header.value]"
                        small
                        class="primaryText--text"
                        outlined
                        @click="addToFilter(item[header.value], header.value)"
                        style="border: none"
                      >
                        {{ formatDate(item[header.value]) }}
                      </v-chip>
                    </div>

                    <div v-else-if="header.value == 'movementType'" class="text-left">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-on="on"
                            small
                            class="primaryText--text"
                            outlined
                            @click="
                              addToFilter(item.movementType, header.value)
                            "
                            style="border: none"
                          >
                            <v-avatar
                              :color="
                                item.movementType == 'EXPORT'
                                  ? 'deep-orange'
                                  : item.movementType == 'IMPORT'? 'blue'
                                  : 'green'
                              "
                              left
                            >
                              <h4 style="color: white">
                                {{ item.movementType.charAt(0) }}
                              </h4>
                            </v-avatar>
                            {{ item.orderNo }}
                            <v-icon
                              small
                              right
                              color="grey"
                              v-if="item.organisationId != $store.state.currentOrg.id">
                              domain_disabled
                              </v-icon>
                          </v-chip>
                        </template>
                        <span>
                          <span
                            v-if="
                              item.organisationId != $store.state.currentOrg.id
                            "
                            >EXTERNAL:
                          </span>
                          {{ $Format.capitalizeFirstLetter(item.movementType) }}
                          Booking
                        </span>
                      </v-tooltip>
                    </div>

                    <div v-else>
                      <v-chip
                        v-if="item[header.value]"
                        small
                        class="primaryText--text"
                        outlined
                        @click="addToFilter(item[header.value], header.value)"
                        style="border: none"
                      >
                        {{ item[header.value] }}
                      </v-chip>
                    </div>
                  </td>
                </div>

                <v-divider class="my-0 py-0"></v-divider>
              </template>
            </v-virtual-scroll>
          </div>
        </div>
        <div class="container" v-if="!loading && displayData.length == 0">
          <h3 style="color: grey">No Results Found</h3>
        </div>
        <div class="container" v-else-if="loading && displayData.length == 0">
          <valhalla-loading height="500px" />
        </div>
      </v-card-text>

      <v-card-text v-if="$vuetify.breakpoint.mobile" class="px-1 pt-0" style="height: calc(100dvh - 56px - 96px)">
        <v-chip
          color="red"
          class="mx-1 mb-1"
          small
          @click="resetFilters()"
          v-if="filteredValues.length > 0"
        >
          <v-icon small left>filter_alt_off</v-icon> Clear Filters
        </v-chip>
        <v-chip
          class="mx-1 mb-1"
          close
          small
          @click:close="addToFilter(filterValue.value, filterValue.field)"
          v-for="(filterValue, index) in filteredValues"
          :key="index"
        >
          {{ filterValue.value }}
        </v-chip>
        <div v-if="displayData.length > 0">
          <v-virtual-scroll
              v-if="displayData.length > 0"
              :bench="10"
              :items="displayData"
              :height="!$vuetify.breakpoint.mobile ? calculatedHeight : 'calc(100dvh - 56px - 96px)'"
              :width="'100%'"
              item-height="680"
              class="px-1"
            >
            <template v-slot:default="{ item }">
          <v-card
            class="mx-1 my-1"
            rounded
            outlined
            tile
            height="670"
          >
          <v-toolbar dense flat class="mobileToolbarCard" style="background-color: var(--v-greyRaised-base) !important">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-chip
                  v-on="on"
                  small
                  outlined
                  @click="addToFilter(item.movementType, header.value)"
                  style="border: none"
                >
                  <v-avatar
                    :color=" 
                      item.movementType == 'EXPORT'
                        ? 'deep-orange'
                        : 'blue'
                    "
                    left
                  >
                    <h4 style="color: white">
                      {{ item.movementType.charAt(0) }}
                    </h4>
                  </v-avatar>
                  {{ item.orderNo }}
                  <v-icon
                    small
                    right
                    color="grey"
                    v-if="
                      item.organisationId != $store.state.currentOrg.id
                    "
                    >domain_disabled</v-icon
                  >
                </v-chip>
              </template>
              <span>
                <span
                  v-if="item.organisationId != $store.state.currentOrg.id"
                  >EXTERNAL:
                </span>
                {{ $Format.capitalizeFirstLetter(item.movementType) }}
                Booking
              </span>
            </v-tooltip>
            <v-spacer></v-spacer>
                <v-btn
                  :color="statusColor(item.state)"
                  small
                  plain
                  @click="addToFilter(item.state, 'state')"
                >
                  <v-icon>{{ statusIcon(item.state) }}</v-icon>
                  <span style="font-size: 12px">{{
                  $Format.capitalizeFirstLetter(item.state)
                }}</span>
                </v-btn>
          </v-toolbar>
          <v-card-text class="px-2">
            <v-row>
              <v-col
                v-for="(header, idx) in mobileHeaders"
                :key="idx"
                class="pb-0"
                cols="6"
                sm="6"
                lg="6"
                style="word-wrap: normal"
              >
                <span style="display: block; font-size: small">
                  <b>{{ header.text }}</b>
                </span>
                <div v-if="header.value == 'actions'">
                  <!-- <v-row align="center" justify="center" class="py-1">
                    <v-checkbox
                      hide-details
                      dense
                      :value="selectedBookings.map((x) => x.id).includes(item.id)"
                      @change="selectBooking(item)"
                      class="mb-2 mx-0 px-0"
                    ></v-checkbox>
                    <v-btn icon @click="viewBooking(item)" small>
                      <v-icon small>launch</v-icon>
                    </v-btn>
                  </v-row> -->
                </div>
                <div
                  v-else-if="header.value == 'userFullName'"
                  class=""
                >
                      <v-avatar
                        style="cursor: pointer"
                        size="18"
                        class="ml-1"
                        :color="item.user.avatar ? 'white' : 'secondary'"
                        @click="addToFilter(item.userFullName, 'userFullName')"
                      >
                        <v-img
                          v-if="item.user.avatar"
                          contain
                          :src="item.user.avatar"
                        ></v-img>
                        <h4 v-else>
                          {{ item.user.firstname.charAt(0) }}
                        </h4>
                      </v-avatar>
                    <span style="font-size: 12px">
                      {{ item.user.firstname }} {{ item.user.surname }}
                    </span>
                </div>

                <div v-else-if="header.value == 'type'" class="">
                  <v-btn
                    :color="bookingTypeColor(item.type)"
                    x-small
                    left
                    plain
                    @click="addToFilter(item.type, 'type')"
                  >
                    <v-icon small left>{{ bookingTypeIcon(item.type) }}</v-icon>
                    <span style="font-size: 12px">{{
                      $Format.capitalizeFirstLetter(item.type)
                    }}</span>
                    </v-btn>
                </div>
                <div
                  v-else-if="header.value == 'vesselVoyage'"
                  class="text-left"
                >
                  <v-chip
                    small
                    class="primaryText--text"
                    outlined
                    @click="addToFilter(item.vesselVoyage, 'vesselVoyage')"
                    style="border: none; font-size: 11px; max-width: 100%; max-height: 5vh; height:auto; white-space: pre-line;"
                  >
                    <v-icon
                      small
                      left
                      v-if="item.isTransshipment"
                      color="orange"
                      >
                      airline_stops
                    </v-icon>
                      <v-icon v-else small left color="grey">
                        navigation
                      </v-icon>
                      <span>
                      {{ item.vesselVoyage }}:
                      <span v-if="item.isTransshipment">Transshipment</span>
                      <span v-else>Direct</span>
                    </span>
                  </v-chip>
                </div>
                <div
                  v-else-if="header.value == 'portOfLoadValue'"
                  class=""
                >
                  <v-tooltip top v-if="item.portOfLoadValue">
                    <template v-slot:activator="{ on }">
                      <v-chip
                        outlined
                        style="border: none; font-size: 11px"
                        v-on="on"
                        small
                        @click="
                          addToFilter(item.portOfLoadValue, 'portOfLoadValue')
                        "
                      >
                        <v-avatar size="20" left>
                          <v-img
                            contain
                            :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                        {{ item.portOfLoadValue }}
                      </v-chip>
                    </template>
                    <span style="font-size: 12px">{{
                      item.portOfLoadCity
                    }}</span>
                  </v-tooltip>
                </div>
                <div
                  v-else-if="header.value == 'finalDestinationValue'"
                  class=""
                >
                  <v-tooltip top v-if="item.finalDestinationValue">
                    <template v-slot:activator="{ on }">
                      <v-chip
                        outlined
                        style="border: none; font-size: 11px"
                        v-on="on"
                        small
                        @click="
                          addToFilter(
                            item.finalDestinationValue,
                            'finalDestinationValue'
                          )
                        "
                      >
                        <v-avatar size="20" left>
                          <v-img
                            contain
                            :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                        {{ item.finalDestinationValue }}
                      </v-chip>
                    </template>
                    <span style="font-size: 12px">{{
                      item.finalDestinationCity
                    }}</span>
                  </v-tooltip>
                </div>
                <div
                  v-else-if="header.value == 'isConfirmedBooking'"
                  class=""
                >
                  <v-btn
                    small
                    icon
                    :color="item.isConfirmedBooking ? 'success' : 'red'"
                    @click="
                      addToFilter(item.isConfirmedBooking, 'isConfirmedBooking')
                    "
                  >
                    <v-icon v-if="item.isConfirmedBooking" small
                      >check_circle_outline</v-icon
                    >
                    <v-icon v-else color="red" small>cancel</v-icon>
                  </v-btn>
                </div>

                <div
                  v-else-if="header.value == 'preadvise'"
                  class=""
                >
                  <v-btn
                    small
                    icon
                    :color="item.preadvise ? 'success' : 'red'"
                    @click="addToFilter(item.preadvise, 'preadvise')"
                  >
                    <v-icon v-if="item.preadvise" small
                      >check_circle_outline</v-icon
                    >
                    <v-icon v-else color="red" small>cancel</v-icon>
                  </v-btn>
                </div>

                <div
                  v-else-if="header.value == 'consigneeName'"
                  class="text-left"
                >
                  <v-tooltip top v-if="item.consignee">
                    <template v-slot:activator="{ on }">
                      <v-chip
                        v-if="item.consignee"
                        small
                        class="primaryText--text"
                        outlined
                        @click="
                          addToFilter(item.consigneeName, 'consigneeName')
                        "
                        v-on="on"
                        style="border: none; white-space: pre-line; max-width: 100%; height: auto; max-height: 5vh; font-size: 12px;"
                      >
                        <v-avatar
                          size="24"
                          :color="
                            item.consignee && item.consignee.logo
                              ? 'white'
                              : 'secondary'
                          "
                          left
                        >
                          <v-img
                            style=""
                            v-if="item.consignee.logo"
                            :src="item.consignee.logo"
                            contain
                          ></v-img>
                          <h3 v-else style="color: white">
                            {{ item.consigneeName.charAt(0) }}
                          </h3>
                        </v-avatar>
                        {{ item.consigneeName }}
                      </v-chip>
                    </template>
                    <span>{{ item.consignee.name }}</span>
                  </v-tooltip>
                </div>
                <div
                  v-else-if="header.value == 'transporterName'"
                  :class="item.transporter ? 'text-left' : ''"
                >
                  <v-tooltip top v-if="item.transporter">
                    <template v-slot:activator="{ on }">
                      <v-chip
                        v-if="item.transporter"
                        small
                        class="primaryText--text"
                        v-on="on"
                        outlined
                        @click="
                          addToFilter(item.transporterName, 'transporterName')
                        "
                        style="border: none; white-space: pre-line; max-width: 100%; height: auto; max-height: 5vh; font-size: 12px;"
                      >
                        <v-avatar
                          size="20"
                          :color="
                            item.transporter && item.transporter.logo
                              ? 'white'
                              : 'secondary'
                          "
                          left
                        >
                          <v-img
                            v-if="item.transporter.logo"
                            :src="item.transporter.logo"
                            contain
                          ></v-img>
                          <h3 v-else style="color: white">
                            {{ item.transporterName.charAt(0) }}
                          </h3>
                        </v-avatar>
                        {{ item.transporterName }}
                      </v-chip>
                    </template>
                    <span>{{ item.transporter.name }}</span>
                  </v-tooltip>
                  <v-btn
                    color="grey"
                    small
                    icon
                    v-else
                    @click="assignContainers([item])"
                    ><v-icon small>add_circle_outline</v-icon></v-btn
                  >
                </div>

                <div
                  v-else-if="header.value == 'customerName'"
                  class="text-left"
                >
                  <v-tooltip top v-if="item.customer">
                    <template v-slot:activator="{ on }">
                      <v-chip
                        v-if="item.customer"
                        small
                        class="primaryText--text"
                        v-on="on"
                        outlined
                        @click="addToFilter(item.customerName, 'customerName')"
                        style="border: none; white-space: pre-line; max-width: 100%; height: auto; max-height: 5vh; font-size: 12px;"
                      >
                        <v-icon
                          class="mr-2"
                          small
                          left
                          v-if="item.clientStatus"
                          :color="getClientStatusColor(item.clientStatus)"
                          >fiber_manual_record</v-icon
                        >
                        <v-avatar
                          size="20"
                          :color="
                            item.customer && item.customer.logo
                              ? 'white'
                              : 'secondary'
                          "
                          left
                        >
                          <v-img
                            v-if="item.customer.logo"
                            :src="item.customer.logo"
                            contain
                          ></v-img>
                          <h3 v-else style="color: white">
                            {{ item.customerName.charAt(0) }}
                          </h3>
                        </v-avatar>
                        {{ item.customerName }}
                      </v-chip>
                    </template>
                    <span
                      >{{ item.customer.name }}
                      <span v-if="item.clientStatus">
                        - Status: {{ item.clientStatus }}</span
                      ></span
                    >
                  </v-tooltip>
                </div>
                <div
                  v-else-if="header.value == 'shipperName'"
                  class="text-left"
                >
                  <v-tooltip top v-if="item.shipper">
                    <template v-slot:activator="{ on }">
                      <v-chip
                        v-if="item.shipper"
                        small
                        class="primaryText--text"
                        v-on="on"
                        outlined
                        @click="addToFilter(item.shipperName, 'shipperName')"
                        style="border: none; white-space: pre-line; max-width: 100%; height: auto; max-height: 5vh; font-size: 12px;"
                      >
                        <v-avatar
                          size="20"
                          :color="
                            item.shipper && item.shipper.logo
                              ? 'white'
                              : 'secondary'
                          "
                          left
                        >
                          <v-img
                            v-if="item.shipper.logo"
                            :src="item.shipper.logo"
                            contain
                          ></v-img>
                          <h3 v-else style="color: white">
                            {{ item.shipperName.charAt(0) }}
                          </h3>
                        </v-avatar>
                        {{ item.shipperName }}
                      </v-chip>
                    </template>
                    <span>{{ item.shipper.name }}</span>
                  </v-tooltip>
                </div>
                <div
                  v-else-if="header.value == 'onBehalfShipperName'"
                  class="text-left"
                >
                  <v-tooltip top v-if="item.onBehalfShipper">
                    <template v-slot:activator="{ on }">
                      <v-chip
                        v-if="item.onBehalfShipper"
                        small
                        class="primaryText--text"
                        v-on="on"
                        outlined
                        @click="
                          addToFilter(
                            item.onBehalfShipperName,
                            'onBehalfShipperName'
                          )
                        "
                        style="border: none; white-space: pre-line; max-width: 100%; height: auto; max-height: 5vh; font-size: 12px;"
                      >
                        <v-avatar
                          size="20"
                          :color="
                            item.onBehalfShipper && item.onBehalfShipper.logo
                              ? 'white'
                              : 'secondary'
                          "
                          left
                        >
                          <v-img
                            v-if="item.onBehalfShipper.logo"
                            :src="item.onBehalfShipper.logo"
                            contain
                          ></v-img>
                          <h3 v-else style="color: white">
                            {{ item.onBehalfShipperName.charAt(0) }}
                          </h3>
                        </v-avatar>
                        {{ item.onBehalfShipperName }}
                      </v-chip>
                    </template>
                    <span>{{ item.onBehalfShipper.name }}</span>
                  </v-tooltip>
                </div>
                <div
                  v-else-if="header.value == 'shippingLineName'"
                  class="text-left"
                >
                  <v-chip
                    v-if="item.shippingLine"
                    small
                    class="primaryText--text"
                    outlined
                    @click="
                      addToFilter(item.shippingLineName, 'shippingLineName')
                    "
                    style="border: none; white-space: pre-line; max-width: 100%; height: auto; max-height: 5vh; font-size: 12px;"
                  >
                    <v-avatar
                      size="24"
                      :color="
                        item.shippingLine && item.shippingLine.logo
                          ? 'white'
                          : 'secondary'
                      "
                      left
                    >
                      <v-img
                        v-if="item.shippingLine.logo"
                        :src="item.shippingLine.logo"
                        contain
                      ></v-img>
                      <h3 v-else style="color: white">
                        {{ item.shippingLineName.charAt(0) }}
                      </h3>
                    </v-avatar>
                    {{ item.shippingLineName }}
                  </v-chip>
                </div>
                <div v-else-if="header.value == 'transportStatus'">
                  <v-menu offset-y v-if="item[header.value]">
                    <template v-slot:activator="{ on }">
                      <v-chip
                        small
                        class="primaryText--text"
                        :color="getStatusColor(item.transportStatus)"
                        v-on="on"
                        style="border: none"
                      >
                        {{ item[header.value] }}
                        <v-icon small right>expand_more</v-icon>
                      </v-chip>
                    </template>
                    <v-list style="overflow: hidden" dense>
                      <v-list-item
                        v-for="(status, index) in transportStatuses"
                        :key="index"
                        style="height: 35px"
                        @click="setStatus(item, status)"
                      >
                        <v-list-item-action>
                          <v-icon :color="status.color"
                            >fiber_manual_record</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ status.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <div v-else-if="header.value == 'etd'">
                  <v-chip
                    v-if="item[header.value]"
                    small
                    class="primaryText--text"
                    outlined
                    @click="addToFilter(item[header.value], header.value)"
                    style="border: none"
                  >
                    {{ formatDate(item[header.value]) }}
                  </v-chip>
                </div>
                <div v-else-if="header.value == 'vesselLegs'">
                  <v-btn
                    v-if="item.sailingSchedule"
                    icon
                    x-small
                    outlined
                    @click="viewBookingLegs(item)"
                    :color="item.sailingSchedule.legs == 1 ? 'success' : 'blue'"
                    >{{ item.sailingSchedule.legs }}</v-btn
                  >
                </div>
                <div v-else-if="header.value == 'numContainers'">
                  <v-chip
                    v-if="item[header.value]"
                    small
                    @click="addToFilter(item[header.value], header.value)"
                  >
                    {{ item[header.value] }}
                  </v-chip>
                  <v-chip small v-else><i>None</i></v-chip>
                </div>
                <!-- <div v-else-if="header.value == 'bookingFileStatus'">
                          <template >
                            <v-chip
                              small
                              icon
                              outlined
                              @click="addToFilter(entry, header.value)"
                              :color="checkFileStatus(entry) === 'Done' ? 'success' : 'orange'"
                    
                            >
                              {{ checkFileStatus(entry) }}
                            </v-chip>
                          </template>
                      
              
                      </div> -->
                <div v-else-if="header.value == 'bookingFileStatus'">
                  <v-tooltip top v-if="item[header.value].length > 0">
                    <template v-slot:activator="{ on }">
                      <v-chip
                        v-if="item[header.value]"
                        small
                        icon
                        outlined
                        @click="addToFilter(item[header.value], header.value)"
                        :color="
                          item[header.value] === 'READY'
                            ? 'success'
                            : 'orange'
                        "
                        v-on="on"
                      >
                        {{ item[header.value]}}
                      </v-chip>
                    <v-chip small v-else><i>None</i></v-chip>
                    </template>
                    <span
                      v-for="(item, index) in popupFileStauts(
                        item.shipmentFileTotals
                      )"
                      :key="index"
                      :style="{ color: item.color }"
                    >
                      {{ item.text }}<br />
                    </span>
                  </v-tooltip>
                  <!-- <v-chip
                          v-if="item[header.value]"
                          small
                          icon
                          outlined
                          @click="addToFilter(item[header.value], header.value)"
                          :color="checkFileStatus(item[header.value]) === 'Done' ? 'success' : 'orange'"
                        >
                          {{ checkFileStatus(item[header.value]) }}
                        </v-chip> -->
                </div>

                <div v-else-if="header.value == 'eta'">
                  <v-chip
                    v-if="item[header.value]"
                    small
                    class="primaryText--text"
                    outlined
                    @click="addToFilter(item[header.value], header.value)"
                    style="border: none"
                  >
                    {{ formatDate(item[header.value]) }}
                  </v-chip>
                  <v-chip small v-else><i>None</i></v-chip>
                </div>



                <div v-else>
                  <v-chip
                    v-if="item[header.value]"
                    small
                    class="primaryText--text"
                    outlined
                    @click="addToFilter(item[header.value], header.value)"
                    style="border: none"
                  >
                    {{ item[header.value] }}
                  </v-chip>
                  <v-chip small v-else><i>None</i></v-chip>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          </v-card>
          </template>
          </v-virtual-scroll>
        </div>
      </v-card-text>
    </v-card>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list dense style="background: var(--v-modal-base) !important">
        <v-subheader v-if="selectedBookingItem" style="font-size: 14px"
          ><v-icon class="mr-2" color="grey">widgets</v-icon>
          {{ selectedBookingItem.orderNo }}</v-subheader
        >
        <v-divider></v-divider>

        <v-list-item @click="viewBooking(selectedBookingItem)">
          <v-list-item-action class="mr-1 pr-1">
            <v-icon small color="grey">launch</v-icon>
          </v-list-item-action>
          <v-list-item-content class="text-left ml-0 pl-0">
            <v-list-item-title>View Booking</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="cloneBooking(selectedBookingItem)">
          <v-list-item-action class="mr-1 pr-1">
            <v-icon small color="grey">content_copy</v-icon>
          </v-list-item-action>
          <v-list-item-content class="text-left ml-0 pl-0">
            <v-list-item-title>Clone Booking</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-progress-circular
              indeterminate
              color="primary"
              v-if="loadingClone"
              size="20"
            ></v-progress-circular>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="selectBooking(selectedBookingItem)">
          <v-list-item-action class="mr-1 pr-1">
            <v-icon small color="grey">check</v-icon>
          </v-list-item-action>
          <v-list-item-content class="text-left ml-0 pl-0">
            <v-list-item-title>Select Booking</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog
      v-model="createBookingModal"
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <BookingModal
        :key="refreshBooking"
        :bookingObj="booking"
        :related="organisations"
        :relatedLoading="loadingOrganisations"
        @close="(createBookingModal = false), (booking = {})"
      />
    </v-dialog>
    <v-dialog
      v-model="selectedBookingModal"
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            {{ selectedBooking.orderNo }}
          </v-toolbar-title>
          <v-toolbar-title v-if="selectedBooking.sailingSchedule">
            {{ selectedBooking.sailingSchedule.vesselDescription }} -
            {{ selectedBooking.sailingSchedule.voyage }}
            <v-chip small outlined style="border: none" class="ml-2">
              <v-avatar size="22" left>
                <v-img
                  contain
                  :src="`https://cdn.loglive.io/flags/4x3/${selectedBooking.sailingSchedule.portOfLoadValue
                    .substring(0, 2)
                    .toLowerCase()}.svg`"
                ></v-img>
              </v-avatar>
              {{ selectedBooking.sailingSchedule.portOfLoadValue }}
            </v-chip>
            <v-icon small class="mx-1">arrow_forward</v-icon>
            <v-chip small outlined style="border: none">
              <v-avatar size="22" class="ml-1" left>
                <v-img
                  contain
                  :src="`https://cdn.loglive.io/flags/4x3/${selectedBooking.sailingSchedule.finalDestinationValue
                    .substring(0, 2)
                    .toLowerCase()}.svg`"
                ></v-img>
              </v-avatar>
              {{ selectedBooking.sailingSchedule.finalDestinationValue }}
            </v-chip>
            <v-chip class="ml-2">
              {{ selectedBooking.sailingSchedule.transit }} Day(s)
            </v-chip>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="selectedBookingModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="3" class="mt-0 pt-1">
              <v-list dense subheader v-if="selectedBooking.sailingSchedule">
                <v-subheader>Vessel Legs</v-subheader>
                <v-divider></v-divider>
                <v-list-item
                  v-if="
                    selectedBooking.sailingSchedule.sailingScheduleLegs
                      .length == 0
                  "
                >
                  <v-list-item-content>
                    <span style="color: grey; font-size: 12px"
                      >No Vessel Legs</span
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-for="leg in selectedBooking.sailingSchedule
                    .sailingScheduleLegs"
                  :key="leg.id"
                >
                  <!-- <v-list-item-action class="mr-1 pr-1">
                                        
                                    </v-list-item-action> -->
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-chip small class="mr-1">{{ leg.leg }}</v-chip>
                      <v-chip small outlined style="border: none">
                        <v-avatar size="20" left>
                          <v-img
                            contain
                            :src="`https://cdn.loglive.io/flags/4x3/${leg.portOfLoadValue
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                        {{ leg.portOfLoadValue }}
                        <v-icon small class="mx-1">arrow_forward</v-icon>
                        <v-avatar size="20" class="ml-1" left>
                          <v-img
                            contain
                            :src="`https://cdn.loglive.io/flags/4x3/${leg.portOfDischargeValue
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                        {{ leg.portOfDischargeValue }}
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-icon small color="blue" class="mr-2">schedule</v-icon>
                      {{ formatDateTime(leg.etd) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-icon small color="green" class="mr-2">schedule</v-icon>
                      {{ formatDateTime(leg.eta) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="leg.vesselDescription">
                      <v-icon small color="grey" class="mr-2"
                        >directions_boat</v-icon
                      >
                      {{ leg.vesselDescription }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="leg.voyage">
                      <v-icon small color="grey" class="mr-2">tag</v-icon>
                      {{ leg.voyage }}
                    </v-list-item-subtitle>
                    <!-- <v-list-item-subtitle>
                                           {{ leg.originLatitude }} {{ leg.originLongitude }}
                                        </v-list-item-subtitle> -->
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="9" v-if="selectedBooking.bookingContainers">
              <v-data-table
                :items="selectedBooking.bookingContainers"
                :loading="loadingContainers"
                dense
                disable-pagination
                hide-default-footer
                :headers="containerHeaders"
                style="background-color: var(--v-modal-base) !important"
              >
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip
                    small
                    class="white--text"
                    :color="containerStatusColor(item)"
                    style="font-weight: bold"
                  >
                    <span v-if="item.isDeleted">Cancelled</span>
                    <span v-else-if="item.status">{{ item.status }}</span>
                    <span v-else>Unallocated</span>
                  </v-chip>
                </template>
                <template v-slot:[`item.features`]="{ item }">
                  <v-tooltip top v-if="item.gensetRequired">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        outlined
                        small
                        class="mx-1"
                        v-on="on"
                        color="#c4c166"
                        ><v-icon small>bolt</v-icon></v-btn
                      >
                    </template>
                    <span>Genset Required</span>
                  </v-tooltip>

                  <v-tooltip top v-if="item.caProduct">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        outlined
                        small
                        class="mx-1"
                        v-on="on"
                        color="teal"
                        ><v-icon small>thermostat_auto</v-icon></v-btn
                      >
                    </template>
                    <span>Controlled Atmosphere: {{ item.caProduct }}</span>
                  </v-tooltip>

                  <v-tooltip top v-if="item.sensorDevice">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        outlined
                        small
                        class="mx-1"
                        v-on="on"
                        color="#c45d1d"
                        ><v-icon small>router</v-icon></v-btn
                      >
                    </template>
                    <span
                      >Monitoring Device:
                      <span v-if="item.sensorDevice">
                        {{ item.sensorDeviceType }}
                        <span v-if="item.sensorService">
                          with {{ item.sensorService }}
                          service
                        </span>
                        <span v-else> without a service </span>
                      </span>
                      <span v-else>None</span>
                    </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="q67Modal" width="750px">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Bulk Create Q67 </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="
              (q67Modal = false),
                (finishedProcessingQ67 = false),
                (finishedProcessingMergedQ67 = false),
                (bulkQ67 = null)
            "
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-btn
                v-if="!finishedProcessingMergedQ67"
                color="teal"
                small
                rounded
                @click="startProcessingMergedQ67()"
                :loading="processingMergedQ67"
                >Generate Merged Q67</v-btn
              >
              <v-row v-else-if="finishedProcessingMergedQ67 && bulkQ67">
                <v-col cols="12" class="mb-0">
                  <v-btn
                    small
                    rounded
                    @click="downloadDocument(bulkQ67.q67)"
                    :loading="processingMergedQ67"
                    >Download Merged PDF</v-btn
                  >
                </v-col>
                <v-col cols="12" class="mt-0">
                  <v-btn
                    small
                    rounded
                    @click="downloadDocument(bulkQ67.q67Excel)"
                    :loading="processingMergedQ67"
                    >Download Merged Excel</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn
                v-if="!finishedProcessingQ67"
                color="blue"
                small
                rounded
                @click="startProcessingQ67()"
                :loading="processingQ67"
                >Generate Individually</v-btn
              >
              <v-btn
                v-else-if="finishedProcessingQ67"
                :loading="downloadingBulkQ67"
                @click="bulkDownloadQ67()"
                small
                class="mx-1"
                color="blue darken-2"
              >
                <v-icon small left>download</v-icon> Download All
              </v-btn>
            </v-col>
          </v-row>
          <v-list
            style="max-height: 50vh; overflow-y: auto"
            :key="q67UpdateKey"
            dense
          >
            <v-list-item v-for="item in selectedBookings" :key="item.id">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.orderNo }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="item.q67">
                  Version: {{ item.q67.version }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="item.errorMessage">
                  Error: {{ item.errorMessage }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-row justify="center">
                  <v-tooltip top v-if="item.q67">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        small
                        @click="viewDocument(item.q67)"
                        v-on="on"
                      >
                        <v-icon color="blue" small>launch</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">View Document</span>
                  </v-tooltip>
                  <v-tooltip top v-if="item.q67">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        small
                        @click="downloadDocument(item.q67)"
                        v-on="on"
                      >
                        <v-icon small>download</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">Download Q67</span>
                  </v-tooltip>
                  <v-tooltip top v-if="item.q67Excel">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        small
                        @click="downloadDocument(item.q67Excel)"
                        v-on="on"
                        color="green"
                      >
                        <v-icon small>browser_updated</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">Download Excel Q67</span>
                  </v-tooltip>
                  <v-icon v-if="item.errorMessage" color="red">error</v-icon>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="previewModal"
      width="1200px"
      :fullscreen="fullscreen || $vuetify.breakpoint.mobile"
    >
      <v-card v-if="item && item.name">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            {{ item.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="downloadDocument(item)" icon
            ><v-icon>download</v-icon></v-btn
          >
          <v-btn @click="fullscreen = !fullscreen" icon
            ><v-icon v-if="!fullscreen">fullscreen</v-icon
            ><v-icon v-else>fullscreen_exit</v-icon></v-btn
          >
          <v-btn icon text @click="(previewModal = false), (item = {})"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text
          :style="{
            'max-height': fullscreen ? '90vh' : '75vh',
            'overflow-y': 'auto',
          }"
        >
          <v-row>
            <v-col cols="12" :loading="imageLoading">
              <div v-if="item.type && item.type.includes('image/')">
                <v-img
                  :src="item.url"
                  contain
                  style="max-height: 70vh"
                  @load="imageLoading = false"
                >
                </v-img>
              </div>
              <div v-else-if="item.type == 'application/pdf'">
                <v-row justify="center">
                  <v-col cols="12" sm="9" class="text-center" v-if="item.url">
                    <div ref="pdfBox">
                      <VuePdfEmbed
                        :width="fullscreen ? 1200 : 900"
                        :source="item.url"
                        @loaded="imageLoading = false"
                      />
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-container v-else fill-height>
                <v-row justify="center" height="100%">
                  <div class="text-center">
                    <span
                      >This file is in a format that cannot be previewed.</span
                    >
                    <br /><br />
                    <v-btn
                      color="primary"
                      style="text-transform: none"
                      @click="downloadDocument(item)"
                    >
                      <v-icon class="mr-1">download</v-icon> Download</v-btn
                    >
                  </div>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="q67CommentModal" width="500px">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Q67</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="(q67CommentModal = false), (q67Comment = null)"
            ><v-icon>close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" sm="6">
              <v-text-field
                outlined
                dense
                label="PPECB N Code"
                v-model="q67Request.ppecbNcode"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                outlined
                dense
                label="Charge Party"
                v-model="q67Request.ppecbInv"
                :items="[
                  'shipper',
                  'forwarder',
                  'onBehalfShipper',
                  'onBehalfForwarder',
                ]"
                item-text="name"
                item-value="name"
              ></v-select>
            </v-col>
          </v-row>
          <v-textarea
            v-model="q67Comment"
            outlined
            placeholder="Addtional Comments (optional)"
          ></v-textarea>
          <v-row justify="center">
            <v-btn @click="(q67Modal = true), (q67CommentModal = false)"
              >Next</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="bottomBar" v-if="$vuetify.breakpoint.mobile">
      <!-- <v-btn
                small
                outlined
                rounded
                class="mx-1"
                @click="changeImportExportFilter"
              >
              </v-btn> -->
      <!---<v-btn icon color="primary" @click="addBooking()"
                ><v-icon>add_circle_outline</v-icon></v-btn
              > -->
      <!-- <v-tooltip top open-delay="500">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-menu
                      offset-y
                      nudge-right
                      :close-on-content-click="false"
                      style="height: 20vh; margin-right: 8vw"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon
                          ><v-icon>view_week</v-icon></v-btn
                        >
                      </template>
                      <v-card
                        style="
                          background: var(--v-modal-base) !important;
                          border-radius: 20px;
                        "
                      >
                        <v-card-text>
                          <v-list
                            style="
                              overflow: hidden;
                              column-count: 3;
                              width: 400px;
                              height: fit-content;
                            "
                          >
                            <v-checkbox
                              v-for="(item, index) in headers.filter(
                                (x) => x.value != 'action'
                              )"
                              :key="index"
                              v-model="item.show"
                              @change="toggleColumn(item)"
                              dense
                              :label="item.text"
                              hide-details
                            ></v-checkbox>
                          </v-list>
                          <v-row class="pt-5 pb-4" style="overflow-y: hidden">
                            <v-col
                              cols="12"
                              md="12"
                              sm="12"
                              lg="12"
                              class="d-flex justify-center"
                            >
                              <v-btn
                                @click="saveCustomHeaders"
                                :loading="loadingSaveCustom"
                                outlined
                                color="primary"
                                >SAVE</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </template>
                Customize Layout
              </v-tooltip> -->
      <v-text-field
        placeholder="Search"
        class="optionsMobile"
        hide-details
        rounded
        outlined
        clearable
        dense
        v-model="search"
      >
        <template v-slot:prepend>
          <v-menu top offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-btn icon plain v-bind="attrs" v-on="on">
                <v-icon>filter_alt</v-icon>
              </v-btn>
            </template>
            <v-card style="width: 30vw; background-color: var(--v-greyBase-base) !important;">
                <v-list dense class="pa-0">
                  <v-list-item @click="changeImportExportFilter('ALL')" :class="importExport == 0 ? 'selectedToggle' : ''">
                    <v-list-item-content>
                      <v-list-item-title> All Bookings </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="changeImportExportFilter('EXPORT')" :class="importExport == 1 ? 'selectedToggle' : ''">
                    <v-list-item-content>
                      <v-list-item-title> Exports </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="changeImportExportFilter('IMPORT')" :class="importExport == 2 ? 'selectedToggle' : ''">
                    <v-list-item-content>
                      <v-list-item-title> Imports </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="changeImportExportFilter('LOCAL')">
                    <v-list-item-content>
                      <v-list-item-title> Local </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              <!-- <v-menu
                :close-on-content-click="false"
                offset-y
                :disabled="selectedBookings.length == 0"
              >
                <template v-slot:activator="{ on }">
                  <v-chip
                    small
                    class="primaryText--text my-2"
                    :color="selectedBookings.length > 0 ? 'blue' : 'grey'"
                    v-on="on"
                    style="border: none; width: 100%; contain: content"
                  >
                    Actions
                    <v-icon
                      style="position: absolute; right: 10px; left: auto"
                      small
                      right
                      >expand_more</v-icon
                    >
                  </v-chip>
                </template>
                <v-list style="overflow: hidden" dense>
                  <v-list-item
                    style="height: 35px"
                    @click="q67CommentModal = true"
                  >
                    <v-list-item-action>
                      <v-icon color="grey">article</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Bulk Create Q67 </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    style="height: 35px"
                    @click="downloadBookingData"
                  >
                    <v-list-item-action>
                      <v-icon color="grey">save_alt</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Download Booking Data
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu> -->
            </v-card>
          </v-menu>
        </template>
      </v-text-field>
    </div>
  </div>
</template>
<script>
import BookingModal from "./BookingModal.vue";
import dateFormat from "dateformat";
import draggable from "vuedraggable";
import * as moment from "moment";
import { PDFDocument } from "pdf-lib";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  components: {
    BookingModal,
    draggable,
    VuePdfEmbed,
  },
  data: () => ({
    allTeams: [],
    booking: {},
    bookings: {
      total: 0,
      data: [],
    },
    bookingTimeout: null,
    bookingQuery: null,
    bookingStatus: [
      { name: "Draft", icon: "edit_note", color: "#F57C00" },
      { name: "Pending", icon: "pending", color: "#009688" },
      { name: "Requested", icon: "verified", color: "#2196F3" },
      { name: "Confirmed", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Ghost", icon: "help", color: "#607D8B" },
      { name: "Cancelled", icon: "cancel", color: "#F44336" },
      { name: "Rejected", icon: "warning", color: "#F44336" },
      { name: "Futile", icon: "warning", color: "#F44336" },
    ],
    bookingTypes: [
      { value: "FREIGHT", icon: "mode_of_travel", color: "blue" },
      { value: "LOGISTICS", icon: "mode_of_travel", color: "blue" },
      { value: "DOCUMENTATION", icon: "article", color: "secondary" },
      { value: "HAULAGE", icon: "local_shipping", color: "blue-grey" },
      { value: "MONITORING", icon: "thermostat", color: "teal" },
    ],
    bulkQ67: null,
    columnDrag: false,
    containerHeaders: [
      {
        value: "status",
        text: "Status",
        align: "center",
      },
      {
        value: "regimeCode",
        text: "Regime Code",
        align: "center",
      },
      {
        value: "ctoNo",
        text: "CTO No.",
        align: "center",
        show: true,
      },
      {
        value: "shipmentFile.fileNumber",
        text: "File",
        align: "center",
        show: true,
      },
      {
        value: "customerContainerRef",
        text: "Customer Ref.",
        align: "center",
        sortable: false,
      },
      {
        value: "containerNo",
        text: "Container No.",
        align: "center",
        sortable: false,
      },
      {
        value: "sealNo",
        text: "Seal No.",
        align: "center",
        sortable: false,
      },
      {
        value: "containerTypeCode",
        text: "Type",
        align: "center",
        sortable: false,
      },
      {
        value: "verificationMethod",
        text: "Method",
        align: "center",
        sortable: false,
      },
      {
        value: "features",
        text: "Features",
        align: "center",
        width: "150px",
        show: true,
        sortable: false,
      },
    ],
    containerStatus: [
      { name: "Unallocated", icon: "warning", color: "orange darken-2" },
      { name: "Ghost", icon: "category", color: "blue-grey" },
      { name: "Moved", icon: "exit_to_app", color: "blue" },
      { name: "Allocated", icon: "task_alt", color: "success" },
      { name: "Cancelled", icon: "cancel", color: "red" },
    ],
    createBookingModal: false,
    dateMenu: false,
    dateKey: 750,
    downloadingBulkQ67: false,
    filteredResults: [],
    filters: null,
    filteredValues: [],
    finishedProcessingQ67: false,
    finishedProcessingMergedQ67: false,
    fullscreen: false,
    headers: [
      {
        text: "Action",
        align: "center",
        disableSort: true,
        disableFilter: true,
        value: "actions",
        width: "100px",
      },
      {
        text: "Regime Code",
        value:"regimeCode",
        align: "center",
        width: "100px",
        disableSort: true,
        filterable: true,
      },
      {
        text: "Status",
        value: "state",
        align: "center",
        width: "100px",
        filterable: true,
      },
      {
        text: "User",
        value: "userFullName",
        align: "center",
        width: "100px",
        filterable: true,
      },
      {
        text: "Order No",
        value: "movementType",
        align: "center",
        filterable: true,
      },

      {
        text: "Type",
        value: "type",
        align: "center",
        filterable: true,
      },

      {
        text: "Vessel",
        value: "vesselVoyage",
        align: "center",
        width: "200px",
      },
      {
        text: "Customer",
        value: "customerName",
        width: "220px",
        filterable: true,
      },
      {
        text: "Shipper",
        value: "shipperName",
        width: "220px",
        filterable: true,
      },
      {
        text: "On Behalf Shipper",
        value: "onBehalfShipperName",
        align: "left",
        filterable: true,
      },
      {
        text: "Consignee",
        value: "consigneeName",
        width: "220px",
        filterable: true,
      },
      {
        text: "Carrier",
        value: "shippingLineName",
        align: "left",
      },
      {
        text: "Week",
        value: "sailingWeek",
        align: "center",
        width: "100px",
      },
      {
        text: "POL",
        value: "portOfLoadValue",
        align: "center",
        width: "100px",
      },
      {
        text: "ETD",
        value: "etd",
        align: "center",
        width: "100px",
      },
      // {
      //     text: 'POD',
      //     value: 'portOfDischargeValue',
      //     align: 'center',
      //     filterable: true
      // },
      {
        text: "Dest.",
        value: "finalDestinationValue",
        align: "center",
        width: "100px",
      },
      {
        text: "ETA",
        value: "eta",
        align: "center",
        width: "100px",
      },
      {
        text: "Legs",
        value: "vesselLegs",
        align: "center",
        width: "100px",
      },
      {
        text: "Containers",
        value: "numContainers",
        align: "center",
        width: "125px",
      },
      {
        text: "File Status",
        value: "bookingFileStatus",
        align: "center",
        width: "125px",
        filterable: true,
      },
      
      {
        text: "Carrier Ref. No",
        value: "carrierReferenceNumber",
        align: "center",
        filterable: false,
      },
      {
        text: "Shipper Ref. No",
        value: "shipperReferenceNo",
        align: "center",
        width: "200px",
        filterable: false,
      },
      {
        text: "Consignee Ref. No",
        value: "consigneeReferenceNo",
        width: "200px",
        align: "center",
        filterable: false,
      },
    ],
    quickSelectDates: [
      // {
      //     text: 'Past 30 days',
      //     type: 'past',
      //     value: 'past_month',
      //     difference: -1
      // },
      {
        text: "Past 7 Days",
        value: "past_week",
        type: "past",
        difference: -7,
      },
      // // {
      // //     text: 'Last 3 Days',
      // //     value: 'past_3day',
      // //     type: 'past',
      // //     difference: -3
      // // },
      // {
      //     text: 'Current 3 Days',
      //     type: 'current',
      //     value: 'current_3day',
      //     difference: 0
      // },
      {
        text: "This week",
        type: "current",
        value: "current_week",
        difference: 0,
      },
      {
        text: "Next 2 weeks",
        type: "future",
        value: "2_week",
        difference: 0,
      },
      {
        text: "Next 6 weeks",
        type: "future",
        value: "6_week",
        difference: 0,
      },
      // {
      //     text: 'This week',
      //     type: 'current',
      //     value: 'current_week',
      //     difference: 0
      // },
      // {
      //     text: 'Month to Date',
      //     type: 'current',
      //     value: 'current_month',
      //     difference: 0
      // },
      // {
      //     text: 'Today',
      //     type: 'current',
      //     value: 'today',
      //     difference: 0
      // },
      // {
      //     text: 'Tomorrow',
      //     type: 'future',
      //     value: 'tomorrow',
      //     difference: 1
      // },
      // {
      //     text: 'Next 7 Days',
      //     type: 'future',
      //     value: 'next_week',
      //     difference: 7
      // },
      // {
      //     text: 'Next 30 Days',
      //     type: 'future',
      //     value: 'next_month',
      //     difference: 30
      // }
    ],
    imageLoading: false,
    importExport: 0,
    item: {},
    loading: false,
    loadingClone: false,
    loadingContainers: false,
    loadingOrganisations: false,
    loadingSaveCustom: false,
    loadingTeams: false,
    myTeams: [],
    organisations: {
      total: 0,
      data: [],
    },
    params: null,
    previewModal: false,
    processingQ67: false,
    processingMergedQ67: false,
    q67CommentModal: false,
    q67Comment: null,
    q67Request: {
      ppecbInv: "forwarder",
    },
    q67Modal: false,
    q67UpdateKey: 450,
    refreshBooking: 1050,
    search: null,
    searchHeaders: {},
    searchKey: 100,
    selectAllBookings: false,
    selectedBookings: [],
    selectedBooking: {},
    selectedBookingModal: false,
    selectedDate: null,
    selectedHeaders: [],
    selectedBookingItem: {},
    settingsChange: false,
    selectedTeams: [],
    selectedWeek: null,
    showMenu: false,
    showCancelled: false,
    sortBy: null,
    sortDesc: false,
    updateFilterValue: 350,
    userLayout: null,
    x: null,
    y: null,
  }),
  watch: {
    selectAllBookings(val) {
      if (val) {
        this.selectedBookings = this.displayData;
      } else {
        this.selectedBookings = [];
      }
    },
    showMenu(val) {
      if (!val) {
        this.selectedBookingItem = {};
      }
    },
    //   'params.selectedDate':{
    //   immediate: true,
    //   handler(val) {
    //     if (val) {

    //     }
    //   }},
  },
  beforeCreate() {
    if (localStorage.bookingParams) {
      this.params = JSON.parse(localStorage.bookingParams);
      if (!this.params.dateType) {
        this.params.dateType = "ETD";
      }
    }
    // if(localStorage['bookingFilters_'+this.$store.state.currentOrg.id]){
    //   this.filters = JSON.parse(localStorage['bookingFilters_'+this.$store.state.currentOrg.id])
    //   let result = [];
    //   let keys = Object.keys(this.filters);
    //   keys.forEach((x) => {
    //     result = [
    //       ...result,
    //       ...this.filters[x].map((y) => ({
    //         field: x,
    //         fieldName: this.headers.find((z) => z.value == x).text,
    //         value: y,
    //       })),
    //     ];
    //   });
    //   this.filteredValues = result;
    // }
  },
  created() {
    if (localStorage.bookingParams) {
      this.params = JSON.parse(localStorage.bookingParams);
      if(!this.params){
        this.params = {}
      }
      if (!this.params.dateType) {
        this.params.dateType = "ETD";
      }
    }
    if (localStorage["bookingFilters_" + this.$store.state.currentOrg.id]) {
      this.filters = JSON.parse(
        localStorage["bookingFilters_" + this.$store.state.currentOrg.id]
      );
      // console.log("existing filters", this.filters)
      if (typeof this.filters != "object") {
        // this.filters = {}
        this.resetFilters();
      }
      let result = [];
      let keys = Object.keys(this.filters);
      keys.forEach((x) => {
        result = [
          ...result,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = result;
    }
    if(!this.params){
        this.params = {}
      }
    if(this.params.queryType){
      delete this.params.queryType
      localStorage.setItem("bookingParams", JSON.stringify(this.params));
    }
    if (!this.params) {
      this.params = {
        dateType: "ETD",
        selectedDate: "current_week",
        dates: [],
      };
      this.selectDate("current_week");
    }
    if (!this.filters) {
      this.filters = {};
      this.resetFilters();
    }
  },
  async mounted() {
    await this.getTeams();
    this.load();
    this.getUserLayout();
    // this.resetFilters();
  },
  computed: {
    mobileHeaders() {
      return this.headers.filter((x) => 
      x.value != "actions" &&
      x.value != "movementType" &&
      x.value != "state"
      );
    },
    numRows() {
      if (this.selectedHeaders.length == 0) {
        return 1;
      }
      return Math.ceil(this.selectedHeaders.length / 2);
    },
    calculatedHeight() {
      let height = window.innerHeight;
      return height * 0.74 + "px";
    },
    // countContainers() {

    //   return this.filteredResults
    //     .map((x) => x.numContainers)
    //     .reduce((a, b) => a + b, 0);
    // },
    countContainers() {
      // Assuming there's a way to check if a filter is applied.
      // This could be a boolean flag or a check based on the contents of `filteredResults`.
      // Replace `isFilterApplied` with the actual condition you use to determine if a filter is active.
      const searchActive = this.search && this.search.length > 0;

      const dataToUse = searchActive ? this.displayData : this.filteredResults;

      return dataToUse.map((x) => x.numContainers).reduce((a, b) => a + b, 0);
    },
    dateText() {
      if (this.params.dates.length === 0) {
        return "Select Dates";
      } else if (this.params.dates.length === 1) {
        return "Week: " + moment(this.params.dates[0]).isoWeek();
      } else {
        return `${this.params.dates[0]} - ${this.params.dates[1]}`;
      }
    },
    displayData() {
      let result = this.filteredResults;
      if (this.showCancelled === false) {
        result = result.filter(
          (x) => (x.state && x.state != 'Cancelled')
        );
      }
      if (this.search) {
        result = result.filter(
          (x) =>
            (x.orderNo &&
              x.orderNo.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.shipperName &&
              x.shipperName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.consigneeName &&
              x.consigneeName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.shippingLineName &&
              x.shippingLineName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.vesselVoyage &&
              x.vesselVoyage
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.regimeCode &&
              x.regimeCode.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.portOfLoadValue &&
              x.portOfLoadValue
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.portOfLoadCity &&
              x.portOfLoadCity
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.finalDestinationValue &&
              x.finalDestinationValue
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.finalDestinationCity &&
              x.finalDestinationCity
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.carrierReferenceNumber &&
              x.carrierReferenceNumber
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.shipperReferenceNo &&
              x.shipperReferenceNo
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.consigneeReferenceNo &&
              x.consigneeReferenceNo
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.customerName &&
              x.customerName.toLowerCase().includes(this.search.toLowerCase()))
        );
      }
      if (this.sortBy) {
        result = result.sort((a, b) => {
          if (this.sortDesc) {
            return a[this.sortBy] < b[this.sortBy] ? 1 : -1;
          } else {
            return a[this.sortBy] > b[this.sortBy] ? 1 : -1;
          }
        });
      }
      return result;
    },
    tableWidth() {
      let result = 0;
      this.selectedHeaders.forEach((x) => {
        result += parseInt(x.width ? x.width.replace("px", "") : 150);
      });
      return result + "px";
    },
  },
  methods: {
    popupFileStauts(file) {
      let arr = [];

      for (let x of file) {
        let obj = {};
        if (x.status == "Processed" || x.status == "Ready") {
          obj.text = x.total + " - " + x.fileNumber + " - " + x.status;
          obj.total = x.total
          obj.fileNumber = x.fileNumber
          obj.status = x.status
          obj.documentationStatus = x.shipment.documentationStatus
          // obj.shipment = x.shipment

          obj.color = "#99B080";
        } else if (x.status == "Draft") {
          obj.text = x.total + " - " + x.fileNumber + " - " + x.status;
          obj.total = x.total
          obj.fileNumber = x.fileNumber
          obj.status = x.status
          // obj.shipment = x.shipment
          obj.color = "orange";
        } else if(x.status == "Not Assigned" || x.status == "Cancelled"){
          obj.text = x.total + " - " + "No Files" + " - " + x.status;
          obj.total = x.total
          obj.fileNumber = x.fileNumber
          obj.status = x.status
          // obj.shipment = x.shipment
          obj.color = "#FF6969";
        }
        arr.push(obj);
      }

      return arr;
    },

    addBooking() {
      this.booking = {
        status: "Draft",
        transportType: "Container",
        movementType: "EXPORT",
        type: "LOGISTICS",
        modeOfTransport: "OCEAN",
        serviceType: "FCL",
        bookingContainerItems: [],
        bookingCargoItems: [],
        bookingPaymentDetails: []
      };
      this.createBookingModal = true;
      this.refreshBooking++;
    },
    addToFilter(value, field) {
      if (this.filters[field].includes(value)) {
        this.filters[field] = this.filters[field].filter((x) => x != value);
      } else {
        this.filters[field].push(value);
      }
      this.updateFilterValue++;
      localStorage.setItem(
        "bookingFilters_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.filters)
      );
      let keys = Object.keys(this.filters);
      let result = [];
      keys.forEach((x) => {
        result = [
          ...result,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = result;
      this.selectAllBookings = false;
      this.selectedBookings = [];
      this.calculateFilteredResults();
    },
    async cargoSummary(booking) {
      let bookingContainers = await this.$API.getBookingContainers(booking.id);
      let result = [];
      let availableMilestones = [
        { value: "EMPTY_PICKUP", type: "EXPORT", name: "Empty Pickup" },
        { value: "LOADING_POINT_1", type: "EXPORT", name: "Loading Point 1" },
        { value: "LOADING_POINT_2", type: "EXPORT", name: "Loading Point 2" },
        { value: "WEIGH_BRIDGE", type: "EXPORT", name: "Weigh Bridge" },
        { value: "FULL_DROP_OFF", type: "EXPORT", name: "Port Holding" },

        { value: "FULL_PICKUP", type: "IMPORT", name: "Full Pickup" },
        { value: "DELIVERY_POINT_1", type: "IMPORT", name: "Delivery Point 1" },
        { value: "DELIVERY_POINT_2", type: "IMPORT", name: "Delivery Point 2" },
        { value: "DELIVERY_POINT_3", type: "IMPORT", name: "Delivery Point 3" },
        { value: "EMPTY_DROP_OFF", type: "IMPORT", name: "Empty Drop Off" },
      ];
      if (booking && bookingContainers) {
        let containers = bookingContainers.filter(
          (x) =>
            !x.isDeleted &&
            (x.status == "Allocated" || x.status == null) &&
            x.bookingId == booking.id
        );
        containers.forEach((x) => {
          let containerProducts = x.containerProducts.map((y) => y.productId);
          containerProducts = containerProducts.sort((a, b) => a - b);
          let temporaryProducts = x.temporaryProducts.map(
            (y) => y.product + y.variation
          );
          let milestones = x.containerMilestones
            .filter((y) =>
              availableMilestones.map((z) => z.value).includes(y.type)
            )
            .map((y) => y.type + y.poiId);
          temporaryProducts = temporaryProducts.sort();
          x.transportType = x.isPrivateHaulage
            ? "Merchant"
            : x.isCarrierHaulage
            ? "Carrier"
            : x.isRailage
            ? "Rail"
            : "Other";
          x.hash =
            x.gensetRequired +
            x.containerTypeCode +
            x.preAdviseRequired +
            x.verificationMethod +
            x.transportCoordinatorId +
            x.transportType +
            containerProducts.join("_") +
            temporaryProducts.join("_") +
            milestones.join("_");
        });
        console.log(containers);
        let uniqueCombinations = [...new Set(containers.map((x) => x.hash))];
        for (let i = 0; i < uniqueCombinations.length; i++) {
          let filtered = containers.filter(
            (x) => x.hash == uniqueCombinations[i]
          );
          let detail = filtered[0];

          let products = [].concat.apply(
            [],
            containers.map((x) => x.containerProducts)
          );
          detail.containerProducts.forEach((x) => {
            delete x.id;
            delete x.bookingContainerId;
            delete x.createdAt;
            delete x.updatedAt;
          });
          detail.containerMilestones.forEach((x) => {
            delete x.id;
            delete x.bookingContainerId;
            delete x.date;
            delete x.time;
            delete x.createdAt;
            delete x.updatedAt;
          });
          detail.temporaryProducts.forEach((x) => {
            delete x.id;
            delete x.bookingContainerId;
            delete x.createdAt;
            delete x.updatedAt;
          });
          let obj = {
            quantity: filtered.length,
            containerTypeCode: detail.containerTypeCode,
            isPrivateHaulage: detail.isPrivateHaulage,
            preAdviseRequired: detail.preAdviseRequired,
            isCarrierHaulage: detail.isCarrierHaulage,
            isRailage: detail.isRailage,
            transportCoordinatorId: detail.transportCoordinatorId,
            transportCoordinator: detail.transportCoordinator,
            bookingCargoItems: detail.containerProducts,
            containerMilestones: detail.containerMilestones,
            temporaryProducts: detail.temporaryProducts,
            vents: detail.vents,
            setPointTemp: detail.setPointTemp,
            humidity: detail.humidity,
            type:
              booking.serviceType == "BREAKBULK" ? "BREAKBULK" : "CONTAINER",
            index: i,
            consignee: detail.consignee,
            consigneeId: detail.consigneeId,
            buyer: detail.buyer,
            buyerId: detail.buyerId,
            verificationMethod:
              booking.movementType == "EXPORT"
                ? "Method 2"
                : detail.verificationMethod,
          };
          result.push(obj);
        }
      }
      return result;
    },
    async downloadMergedQ67() {},
    async bulkDownloadQ67() {
      this.downloadingBulkQ67 = true;
      const combinedPdf = await PDFDocument.create();
      for (let x = 0; x < this.selectedBookings.length; x++) {
        const pdfBytes = await fetch(this.selectedBookings[x].q67.url).then(
          (res) => res.arrayBuffer()
        );
        const pdfDoc = await PDFDocument.load(pdfBytes);

        const pages = Array.from(
          { length: pdfDoc.getPageCount() },
          (_, i) => i
        );
        const copiedPages = await combinedPdf.copyPages(pdfDoc, pages);

        for (const page of copiedPages) {
          combinedPdf.addPage(page);
        }
      }

      const combinedPdfBytes = await combinedPdf.save();

      // Trigger download for the combined PDF
      const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "combined_q67.pdf";
      link.click();
      this.downloadingBulkQ67 = false;
    },
    changeDateType() {
      if (this.params.dateType == "ETD") {
        this.params.dateType = "ETA";
      } else {
        this.params.dateType = "ETD";
      }
      this.dateKey++;
      this.getBookings();
    },
    changeImportExportFilter(movementType) {
      if (movementType == "ALL") {
        this.importExport = 0;
        this.filters.movementType = [];
      } else {
        this.filters.movementType = [movementType];
        this.importExport = movementType == "EXPORT" ? 1 : movementType == "IMPORT" ? 2:3;
      }
      // if (this.importExport == 0) {
      //   this.importExport = 1;
      //   this.filters.movementType = ["IMPORT"];
      // } else {
      //   this.importExport = 0;
      //   this.filters.movementType = ["EXPORT"];
      // }
      this.calculateFilteredResults();
      this.updateFilterValue++;
    },
    async cloneBooking(item) {
      this.loadingClone = true;
      let booking = await this.$API.getBookingDetail(item.systemReference);
      if (booking.consigneeProfileId) {
        booking.consigneeProfile = await this.$API.getConsigneeProfile(
          booking.consigneeProfileId
        );
      }
      let cargoDetails = await this.cargoSummary(booking);
      console.log('cargoDetails', cargoDetails)
      cargoDetails.forEach((detail) => {
      detail.containerMilestones = detail.containerMilestones.filter(milestone => milestone.type !== "PLUG_IN" && milestone.type !== "PLUG_OUT");
      detail.bookingCargoItems.forEach((item) => {
        delete item.id;
        delete item.bookingContainerId;
        delete item.createdAt;
        delete item.updatedAt;
      })
      });
      console.log('cargoDetails',cargoDetails)
      delete booking.id;
      delete booking.shipperReferenceNo;
      delete booking.consigneeReferenceNo;
      delete booking.carrierReferenceNumber;
      delete booking.integrationReference;
      delete booking.integrationChange;
      delete booking.qxReference;
      delete booking.q67ExcelId;
      delete booking.q67Excel;
      delete booking.q67Id;
      delete booking.q67;

      delete booking.systemReference;
      delete booking.user;
      delete booking.userId;
      delete booking.orderNo;
      delete booking.createdAt;
      delete booking.updatedAt;
      delete booking.exportNotificationStatus
      delete booking.exportNotificationData
      delete booking.exportNotificationReference
      booking.bookingPaymentDetails.forEach((x) => {
        delete x.id;
        delete x.bookingId;
        delete x.createdAt;
        delete x.updatedAt;
      });
      this.booking = { ...booking };
      this.booking.bookingContainerItems = cargoDetails;
      this.booking.status = "Draft";
      // this.booking.bookingContainerItems= []
      this.loadingClone = false;
      this.createBookingModal = true;
      this.refreshBooking++;
    },
    bookingTypeColor(type) {
      let find = this.bookingTypes.find((x) => x.value == type);
      return find ? find.color : null;
    },
    bookingTypeIcon(type) {
      let find = this.bookingTypes.find((x) => x.value == type);
      return find ? find.icon : null;
    },
    calculateFilteredResults() {
      let filterKeys = Object.keys(this.filters);
      let filterResult = [];
      filterKeys.forEach((x) => {
        filterResult = [
          ...filterResult,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = filterResult;
      let result = [...this.bookings.data];
      let keys = Object.keys(this.filters);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (this.filters[key] && this.filters[key].length > 0) {
          result = result.filter((x) => this.filters[key].includes(x[key]));
        }
      }
      this.filteredResults = result;
    },
    changeSortBy(value) {
      if (this.sortBy == value) {
        if (this.sortDesc) {
          this.sortBy = null;
          this.sortDesc = false;
        } else {
          this.sortDesc = true;
        }
      } else {
        this.sortBy = value;
        this.sortDesc = false;
      }
      this.selectAllBookings = false;
      this.selectedBookings = [];
    },
    containerStatusColor(item) {
      if (item.isDeleted) {
        return "red";
      } else if (item.status) {
        let find = this.containerStatus.find(
          (x) => x.name.toLowerCase() == item.status.toLowerCase()
        );
        if (find) {
          return find.color;
        } else {
          return "orange darken-2";
        }
      } else {
        return "orange darken-2";
      }
    },
    async downloadDocument(doc) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.url;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    getClientStatusColor(status) {
      switch (status) {
        case "GOOD STANDING":
          return "green";
        case "INACTIVE":
          return "red";
        case "ON HOLD":
          return "orange";
        case "COD":
          return "blue";
      }
    },
    async getBookings() {
      if (this.bookingTimeout) {
        clearTimeout(this.bookingTimeout);
      }
      if (this.loading && this.bookingQuery) {
        this.bookingQuery.abort();
      }
      this.loading = true;
      let params = { ...this.params };
      params.teams = this.selectedTeams;
      if (this.params.dates.length == 1) {
        params.dates = [
          moment(this.params.dates[0])
            .startOf("isoWeek")
            .toISOString()
            .substring(0, 10),
          moment(this.params.dates[0])
            .endOf("isoWeek")
            .toISOString()
            .substring(0, 10),
        ];
      }
      this.selectedBookings = [];
      this.bookingTimeout = setTimeout(async () => {
        this.bookingQuery = new AbortController();
        const signal = this.bookingQuery.signal;
        this.bookings = await this.$API.getBookingOverview({
          params: params,
          signal,
        });
        this.calculateFilteredResults();
        this.loading = false;
      }, 500);
    },
    async getBookingContainers() {
      this.loadingContainers = true;
      this.selectedBooking.bookingContainers =
        await this.$API.getBookingContainersBasic(this.selectedBooking.id);
      this.loadingContainers = false;
    },
    async getRelations() {
      this.loadingOrganisations = true;
      this.organisations = await this.$API.getRelationBasic({
        // params: this.params,
      });
      this.loadingOrganisations = false;
    },
    async getUserLayout() {
      this.selectedHeaders = this.headers;
      this.userLayout = await this.$API.getUserTableLayout({
        params: {
          module: "Bookings",
        },
      });
      if (this.userLayout && this.userLayout.config) {
        this.selectedHeaders = this.userLayout.config.filter((x) =>
          this.headers.map((y) => y.value).includes(x.value)
        );
      } else {
        this.selectedHeaders = this.headers;
      }
    },
    async getTeams() {
      this.loadingTeams = true;
      this.allTeams = await this.$API.getTeams();
      this.myTeams = await this.$API.getUserTeams();
      if (localStorage["bookingTeams_" + this.$store.state.currentOrg.id]) {
        this.selectedTeams = JSON.parse(
          localStorage["bookingTeams_" + this.$store.state.currentOrg.id]
        );
      } else {
        this.selectedTeams = this.myTeams.map((x) => x.teamId);
      }
      this.loadingTeams = false;
    },
    headerOptions(value) {
      let result = [
        ...new Set(
          this.bookings.data
            // .filter(x => x[value])
            .map((x) => ({ name: x[value] ?? "(Blank)", value: x[value] }))
        ),
      ];
      let uniqueValues = [...new Set(result.map((x) => x.value))];
      if (this.searchHeaders[value]) {
        uniqueValues = uniqueValues.filter(
          (x) =>
            x &&
            x.toLowerCase().includes(this.searchHeaders[value].toLowerCase())
        );
      }
      result = uniqueValues.map((x) => result.find((y) => y.value === x));
      // if (value == 'status') {
      //     result.push({ name: 'Unallocated', value: null })
      // }
      result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      return result;
    },
    async load() {
      this.getBookings();
      await this.getRelations();
    },
    resetFilters() {
      this.filters = {};
      let keys = this.headers.map((x) => x.value);
      keys.forEach((x) => {
        this.searchHeaders[x] = null;
        this.filters[x] = [];
      });
      this.filteredValues = [];
      this.selectedBookings = [];
      this.selectAllBookings = false;
      this.updateFilterValue++;
      localStorage.removeItem(
        "bookingFilters_" + this.$store.state.currentOrg.id
      );
      this.calculateFilteredResults();
    },
    async saveCustomHeaders() {
      this.loadingSaveCustom = true;
      if (this.userLayout && this.userLayout.id) {
        let result = await this.$API.updateUserTableLayout({
          id: this.userLayout.id,
          config: this.selectedHeaders,
        });
        this.settingsChange = false;
        this.userLayout = result;
        this.userLayout.config = this.selectedHeaders;
        this.$message.success("Successfully updated!");
      } else {
        let result = await this.$API.createUserTableLayout({
          module: "Bookings",
          config: this.selectedHeaders,
        });
        this.settingsChange = false;
        this.userLayout = result;
        this.userLayout.config = this.selectedHeaders;
        this.$message.success("Successfully saved setup!");
      }
      this.loadingSaveCustom = false;
    },
    selectDate(value) {
      this.params.selectedDate = value;
      this.setDateRange(value);
      console.log("Setting params", JSON.stringify(this.params));
      localStorage.setItem("bookingParams", JSON.stringify(this.params));
    },
    setDateFilter() {
      if (this.params.dates.length == 2) {
        if (this.params.dates[1] < this.params.dates[0]) {
          let dates = [this.params.dates[1], this.params.dates[0]];
          this.params.dates = dates;
        }
      }
      localStorage.setItem("bookingParams", JSON.stringify(this.params));
      this.getBookings();
      this.dateMenu = false;
    },
    setDateRange(val) {
      switch (val) {
        case "current_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 30))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_week":
          this.params.dates = [
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
            )
              .toISOString()
              .substring(0, 10),
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 7)
            )
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_month":
          this.params.dates = [
            new Date(new Date().setDate(1)).toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "past_day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 3))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 7))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "today":
          this.params.dates = [
            new Date().toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "tomorrow":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 7))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "2_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 14))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "6_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 42))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
      }
      this.getBookings();
    },
    statusColor(status) {
      let color = this.bookingStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return color ? color.color : "grey";
    },
    statusIcon(status) {
      let find = this.bookingStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return find ? find.icon : "";
    },
    selectBooking(item) {
      let find = this.selectedBookings.find((x) => x.id == item.id);
      if (!find) {
        this.selectedBookings.push(item);
      } else {
        this.selectedBookings = this.selectedBookings.filter(
          (x) => x.id != item.id
        );
      }
    },
    selectAllTeams() {
      this.selectedTeams = this.allTeams.map((x) => x.id);
      localStorage.setItem(
        "bookingTeams_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.selectedTeams)
      );
      this.getBookings();
    },
    selectTeam(id) {
      let findIndex = this.selectedTeams.indexOf(id);
      if (findIndex > -1) {
        this.selectedTeams.splice(findIndex, 1);
      } else {
        this.selectedTeams.push(id);
      }
      localStorage.setItem(
        "bookingTeams_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.selectedTeams)
      );
      this.getBookings();
    },
    async startProcessingMergedQ67() {
      this.processingMergedQ67 = true;
      let result = await this.$API.generateMergedQ67({
        bookings: this.selectedBookings.map((x) => x.id),
        comment: this.q67Comment,
        ...this.q67Request,
      });
      this.bulkQ67 = result;
      this.downloadDocument(this.bulkQ67.q67);
      this.q67UpdateKey++;
      this.finishedProcessingMergedQ67 = true;
      this.processingMergedQ67 = false;
    },
    async startProcessingQ67() {
      this.processingQ67 = true;
      for (let i = 0; i < this.selectedBookings.length; i++) {
        let result = await this.$API.generateQ67({
          bookingId: this.selectedBookings[i].id,
          comment: this.q67Comment,
          ...this.q67Request,
        });
        if (result && result.q67) {
          this.selectedBookings[i].q67 = result.q67;
          this.selectedBookings[i].q67Id = result.q67Id;
          this.selectedBookings[i].q67Excel = result.q67Excel;
          this.selectedBookings[i].q67ExcelId = result.q67ExcelId;
        } else if (result && result.message) {
          this.selectedBookings[i].errorMessage = result.message;
        }

        this.q67UpdateKey++;
      }
      this.finishedProcessingQ67 = true;
      this.processingQ67 = false;
    },
    async downloadBookingData() {
      let bookings = this.selectedBookings.map((x) => x.id);
      let response = await this.$API.getBookingBulkDownload(bookings);

      if (response && response.dataUri) {
        const link = document.createElement("a");
        link.href = response.dataUri;

        // Update the 'download' attribute to reflect Excel file extension (.xlsx)
        link.setAttribute("download", `LoadsReport.xlsx`);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("No data received from server");
      }
    },

    toggleColumn(item) {
      if (item.show === true) {
        this.headers.find((header) => header.value === item.value).show = true;
      } else {
        this.headers.find((header) => header.value === item.value).show = false;
      }
      this.selectedHeaders = this.headers.filter((x) => x.show);
    },
    updateSearchKey() {
      this.searchKey++;
    },
    updateUserLayout() {
      this.settingsChange = true;
    },
    viewBooking(item) {
      this.$router.push({
        path: "/booking/" + item.systemReference,
      });
    },
    viewBookingLegs(item) {
      this.selectedBooking = item;
      console.log(item);
      if (!this.selectedBooking.bookingContainers) {
        this.selectedBooking.bookingContainers = [];
        this.getBookingContainers(this.selectedBooking);
      }
      this.selectedBookingModal = true;
    },
    viewDocument(item) {
      this.item = item;
      if (
        this.item.type &&
        (this.item.type.includes("image/") ||
          this.item.type.includes("application/pdf"))
      ) {
        this.imageLoading = true;
      }
      this.previewModal = true;
    },
    viewItem(e, item) {
      e.preventDefault()
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.selectedBookingItem = item
      this.$nextTick(() => {
        this.showMenu = true
      })
    },
  },
};
</script>
<style scoped>

.mobileToolbarCard :is(.v-toolbar__content) {
  padding: 5px !important;
}

.optionsMobile :is(.v-input__prepend-outer) {
  margin: 4px 4px 0 0 !important;
}
.bottomBar {
  height: 3rem;
  width: 100dvw;
  position: fixed;
  display: flex;
  padding: 0.2rem 0.5rem;
  bottom: 0;
  background-color: var(--v-greyRaised-base) !important;
  align-content: center;
}
.container {
  width: 100%;
  height: 550px;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.green {
  color: #004225;
}

.orange {
  color: orange;
}

.blue{
  color: blue;
}
.red {
  color: #200e3a;
}
.selectedToggle {
  background-color: var(--v-greyBase-lighten1) !important;
}
/* th div {
    border: 0;
    min-height: 20px;
    min-width: 20px;
} */
</style>